.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Ajustez la hauteur selon vos besoins */
  }
  
  .loader {
    width: 200px; /* Ajustez la largeur du loader selon vos besoins */
    height: 20px; /* Ajustez la hauteur du loader selon vos besoins */
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .progress {
    height: 100%;
    background-color: #007bff; /* Couleur de fond de la barre de progression */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff; /* Couleur du texte pourcentage */
    font-weight: bold;
    transition: width 0.5s ease-in-out; /* Ajoutez une transition douce à la largeur de la barre de progression */
  }
  