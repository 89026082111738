body {
  background-color: #f8f9fa;
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif; /* Adjust font-family as needed */
}

.main-container {
  position: absolute;
  padding: 8% 5% 8%; /* Top and horizontal padding with increased bottom padding */
  width: 100%;
  left: 25%;
  max-width: 800%; /* Maximum width set to 2600px */
  margin: 0 auto; /* Horizontally center the container */
  box-sizing: border-box; /* Include padding and border in the width and height */
}


.sidebar {
  padding: 20px;
  background-color: #343a40;
  color: #fff;
}

.sidebar-right {
  padding: 20px;
}

button {
  margin: 10px;
}

.card {
  margin-bottom: 20px;
}

.main-content {
  padding: 30px;
}

.list-group-item {
  display: flex;
  align-items: center;
}

.amount {
  margin-left: auto;
  padding-left: 10px;
  font-weight: bold;
}

.custom-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  margin-bottom: 10px;
}

.custom-list-item img {
  margin-right: 10px;
}

.custom-list-item .amount {
  font-weight: bold;
}

@media (max-width: 768px) {
  .sidebar, .sidebar-right, .main-content {
    padding: 10px;
  }

  .card {
    margin-bottom: 10px;
  }
  
  .list-group-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .amount {
    margin-left: 0;
    padding-left: 0;
    margin-top: 5px;
  }

  button {
    width: 100%;
    margin: 5px 0;
  }
}

@media (max-width: 576px) {
  .main-container {
    padding: 5%;
  }

  .main-content, .sidebar, .sidebar-right {
    padding: 10px;
  }

  .list-group-item {
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  .amount {
    margin-top: 5px;
  }
}
