/* Admin.css */
.sidebar {
    padding-top: 20px; /* Ajouter de l'espace en haut de la barre latérale */
    height: 100%;
    width: 250px; /* Initial width */
    position: fixed;
    top: 0;
    left: 0;
    background-color: #343a40; /* Sidebar background color */
    padding-top: 20px; /* Padding at the top */
    overflow-y: auto; /* Enable scrolling for small screens */
    z-index: 1; /* Ensure sidebar is above content */
    transition: width 0.3s; /* Smooth transition for changing width */
  }
  
  .sidebar a {
    padding: 10px 15px; /* Padding for each sidebar item */
    text-decoration: none;
    color: #ffffff; /* Text color */
    display: block;
  }
  
  .sidebar a:hover {
    background-color: #495057; /* Hover background color */
  }
  
  .content {
    margin-left: 250px; /* Initial margin */
    padding: 20px;
  }
  
  .content h1 {
    color: #000000; /* Heading color */
  }
  
.sidebar-link {
    font-size: 18px; /* Augmenter la taille de la police des liens */
    margin-bottom: 10px; /* Ajouter de l'espace entre les liens */
  }
  
  @media (max-width: 768px) {
    .sidebar {
      width: 0; /* Hide sidebar by default on smaller screens */
    }
    
    .content {
      margin-left: 0; /* Move content to the left on smaller screens */
    }
  }
  