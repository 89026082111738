/* App.css */
.card-background {
    background-image: url('./img/bg.png');
    background-size: cover; /* Pour couvrir toute la carte */
    background-position: center; /* Pour centrer l'image */
    color: white; /* Assurez-vous que le texte est lisible */
    border-radius: 15px; /* Ajout du border-radius */
}

.card-body {
  position: relative;
 
}

.mastercard-logo {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 40px; /* Ajustez la taille du logo selon vos besoins */
  height: auto;
}