.faq {
    color: rgb(5, 5, 5); /* Texte en blanc */
}

.accordion-single {
    background-color: rgb(var(--p1)); /* Couleur de fond des sections de l'accordéon */
    color: white; /* Texte en blanc */
    border: none; /* Enlever les bordures si nécessaire */
}

.accordion-btn {
    color: white; /* Couleur du texte du bouton */
}

.accordion-btn::after {
    color: white; /* Couleur de l'icône du bouton (si présente) */
}

.accordion-single .content-body {
    color: white; /* Couleur du texte du contenu */
}

.accordion-single.active .content-area {
    display: block;
    color: white; /* Couleur de l'icône du bouton (si présente) */

}
