.cookie-consent {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    padding: 1rem;
    z-index: 1000;
  }
  
  .cookie-consent__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .cookie-consent__button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cookie-consent__button:hover {
    background-color: #45a049;
  }
  