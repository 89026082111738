/* Update.css */
.sidebar {
    background-color: #343a40; /* Couleur de fond de la barre latérale */
    color: white; /* Couleur du texte de la barre latérale */
    padding-top: 20px; /* Ajouter de l'espace en haut de la barre latérale */
}

.sidebar-link {
    font-size: 18px; /* Taille de la police des liens de la barre latérale */
    margin-bottom: 10px; /* Ajouter de l'espace entre les liens de la barre latérale */
}

.card {
    border-radius: 15px; /* Coins arrondis pour les cartes */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombre douce pour les cartes */
}

.form-control {
    border-radius: 10px; /* Coins arrondis pour les champs de formulaire */
}

.btn-primary {
    background-color: #007bff; /* Couleur de fond du bouton primaire */
    border: none; /* Supprimer la bordure du bouton primaire */
    border-radius: 10px; /* Coins arrondis pour le bouton primaire */
}

.btn-primary:hover {
    background-color: #0056b3; /* Couleur de fond au survol du bouton primaire */
}
